import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function OpenTable(props) {

 let dataSet = props.data;
  function outputRow(label, data) {
    return (
      <>
        <TableCell component="th" scope="row">{label}</TableCell>
        {
          data.map((item, index) => (
            <TableCell key={index}>{props.type == 'users' ? item : item.toFixed(4)}</TableCell>
          ))
        }
      </>
    );
  }

  let userTable = ()=>{
    if(props.type === "users"){
      return(
        <TableBody>
          {/* <TableRow>{outputRow("Total Connected", dataSet.dataItem2)}</TableRow> */}
          <TableRow>{outputRow("New Users", dataSet.newUsers)}</TableRow>
          <TableRow>{outputRow("New Active users", dataSet.activeUsers)}</TableRow>
          <TableRow>{outputRow("New Unreferred Users", dataSet.newUnreferred)}</TableRow>
          <TableRow>{outputRow("Old Users", dataSet.oldUsers)}</TableRow>
          <TableRow>{outputRow("Old Active Users", dataSet.oldActiveUsers)}</TableRow>
        </TableBody>
      )
    } else {
      return(
        <TableBody>
          <TableRow>{outputRow("Total Swaps", dataSet.totalSwaps)}</TableRow>
          <TableRow>{outputRow("Total Volume", dataSet.totalVolumes)}</TableRow>
          <TableRow>{outputRow("Total Fees", dataSet.totalFees)}</TableRow>
        </TableBody>
      )
    }
  }
  

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row">Dataset</TableCell>
            {dataSet.dates.map((item) => (
              <TableCell key={item}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {userTable()}
      </Table>
    </TableContainer>
  );
}