import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function OpenChart(props) {
    return <Line className='pb-5' options =
    {
        {
            responsive: true,
            plugins: {
              legend: {
                  position: props.position,
              },
              title: {
                  display: true,
                  text: props.titleText,
              },
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              }
            }
        }
    } data = {
        props.data.id === 2 ? {
          labels: props.data.dates,
          datasets: [
            {
              label: "Total Swaps",
              data: props.data.totalSwaps,
              borderColor: 'red',
              tension: 0.5,
              backgroundColor: 'white',
            },
            {
              label: "Total Volumes",
              data: props.data.totalVolumes,
              borderColor: 'blue',
              tension: 0.5,
              backgroundColor: 'white',
            },
            {
              label: "Total Fees",
              data: props.data.totalFees,
              borderColor: 'green',
              tension: 0.5,
              backgroundColor: 'white',
            }
          ],
        } : {
          labels: props.data.dates,
          datasets: [
            {
              label: "New Users",
              data: props.data.newUsers,
              borderColor: 'red',
              tension: 0.5,
              backgroundColor: 'white',
            },
            {
              label: "Active Users",
              data: props.data.activeUsers,
              borderColor: 'blue',
              tension: 0.5,
              backgroundColor: 'white',
            }
          ],
        }
    }
    />;
}