import React from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ScaleText from "react-scale-text";

export function CardData(props) {
    var passedData = {
        type: props.type,
        labels: { 
            label1: props.labels.label1,
            label2: props.labels.label2
        },
        values: {
            value1: props.values.value1,
            value2: props.values.value2
        }
    }

    if(passedData.type === "single") {
        return ( // single card
            <Grid item xs={3}>
                <Box sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 1,
                        p: 2,
                        minWidth: 200,
                    }}
                >
                    <Box sx={{ color: 'text.secondary' }}>
                        { passedData.labels.label1 }
                    </Box>
                    <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                        <ScaleText>
                            { passedData.values.value1 }
                        </ScaleText>
                    </Box>
                </Box>
            </Grid>
            );
    } else if(passedData.type === "double") {
        return ( // double card
            <Grid item xs={5}>
                <Box sx={{
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        borderRadius: 1,
                        p: 2,
                        minWidth: 200,
                    }}
                >
                    <div className='d-flex justify-content-around'>
                        <div className='d-block'>
                            <Box sx={{ color: 'text.secondary' }}>
                                { passedData.labels.label1 }
                            </Box>
                            <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                                <ScaleText>
                                    { passedData.values.value1 }
                                </ScaleText>
                            </Box>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className='d-block'>
                            <Box sx={{ color: 'text.secondary' }}>
                                { passedData.labels.label2 }
                            </Box>
                            <Box sx={{ color: 'text.primary', fontSize: 34, fontWeight: 'medium' }}>
                                <ScaleText>
                                    { passedData.values.value2 }
                                </ScaleText>
                            </Box>
                        </div>
                    </div>
                </Box>
            </Grid>
        );
    }
};