import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Link } from "react-router-dom";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons/faUserAlt";
import { faRocket } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";



class Dashboard extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        //const { user } = this.props.auth;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Dashboard</h1>

                            <div className="row px-2">

                                <div className="col-sm-3 p-sm-2">
                                    <div className="card bg-secondary text-white shadow-lg">
                                        <div className="card-body">
                                            <h5 className="card-title">Farms</h5>
                                            {/* <p className="card-text">With supporting text below as a natural lead-in to
                                                additional content.</p> */}
                                            <Link to="/Farms" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faTruck} /> Go to Farms</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card bg-info text-white shadow-lg">
                                        <div className="card-body">
                                            <h5 className="card-title">Pools</h5>
                                            {/* <p className="card-text">With supporting text below as a natural lead-in to
                                                additional content.</p> */}
                                            <Link to="/Pools" className="btn btn-light"><FontAwesomeIcon className="text-primary" icon={faRocket} /> Go to Pools</Link>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);
