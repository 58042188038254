import React, { Component, useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { OpenChart } from "../partials/OpenChart";
import { CardData } from "../partials/CardData";
import axios from "axios";
import config from "../../lib/config";
import Grid from "@mui/material/Grid";
import { OpenTable } from "../partials/OpenTable";

var backurl = config.baseurl;

export default function Analytics() {

    const [opt , setOpt] = useState(0)
    const [data, setData] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        axios
        .post(backurl + "/api/get-wallets-live")
        .then(res => {
            res.data.wallets ? setData(res.data.wallets) : setData(false);
        })
        .catch();
    }, []);

    useEffect(() => {
        if(opt != 0) {
            axios
            .post(backurl + "/api/get-wallets", { opt: opt })
            .then(res => {
                let dummyData = {
                    dates: [],
                    newUsers: [],
                    activeUsers: [],
                    newUnreferred: [],
                    oldUsers: [],
                    oldActiveUsers: [],
                    id: 1
                }
                
                res.data.data.forEach(data => {
                    let dummyDate = new Date(data.createdAt).toUTCString().split(' ');
                    dummyData.dates.push(dummyDate[0] + " " + dummyDate[1] + " " + dummyDate[2]);
                    dummyData.newUsers.push(data.new.connected);
                    dummyData.activeUsers.push(data.new.active);
                    dummyData.newUnreferred.push(data.new.unreferred);
                    dummyData.oldUsers.push(data.old.connected);
                    dummyData.oldActiveUsers.push(data.old.active);
                });
                setChartData(dummyData);
            })
            .catch();
        }
    }, [opt]);

    return (
        data ?
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <div className="container text-center">
                        <h2 className="text-primary mt-4 text-left">All Time</h2>
                        <hr/>
                        
                        <Grid className="pt-4" container spacing={6}>
                            <CardData type="single" labels={{label1: "Total Wallets",label2: ""}} values={{value1: data.total,value2: ""}} />
                            <CardData type="double" labels={{label1: "Active Wallets",label2: "Inactive Wallets"}} values={{value1: data.active,value2: data.total - data.active}} />
                            <CardData type="single" labels={{label1: "Unreferred Wallets",label2: ""}} values={{value1: data.unreferred,value2: ""}} />
                        </Grid>

                        <h2 className="text-primary mt-4 text-left pt-4">Today</h2>
                        <hr/>

                        <Grid className="pt-4" container spacing={6}>
                            <CardData type="single" labels={{label1: "Total connected wallets",label2: ""}} values={{value1: data.new.connected + data.old.connected,value2: ""}} />
                            <CardData type="double" labels={{label1: "Connected Wallets (NEW)",label2: "Connected Wallets (OLD)"}} values={{value1: data.new.connected,value2: data.old.connected }} />
                            <CardData type="single" labels={{label1: "Unreferred Wallets (NEW)",label2: ""}} values={{value1: data.new.unreferred,value2: ""}} />
                        </Grid>

                        <Grid className="pt-4" container spacing={6}>
                            <CardData type="double" labels={{label1: "Active Wallets (NEW)",label2: "Inactive Wallets (NEW)"}} values={{value1: data.new.active,value2: data.new.connected - data.new.active}} />
                            <CardData type="double" labels={{label1: "Active Wallets (OLD)",label2: "Inactive Wallets (OLD)"}} values={{value1: data.old.active,value2: data.old.connected - data.old.active}} />
                        </Grid>

                        <ButtonGroup className="pt-5 pb-4" aria-label="outlined primary button group">
                            <Button variant={opt === 1 ? "contained" : "outlined"} onClick={() => {setOpt(1)}}>All Time</Button>
                            <Button variant={opt === 4 ? "contained" : "outlined"} onClick={() => {setOpt(4)}}>Last 30 days</Button>
                            <Button variant={opt === 3 ? "contained" : "outlined"} onClick={() => {setOpt(3)}}>Last 7 days</Button>
                            <Button variant={opt === 2 ? "contained" : "outlined"} onClick={() => {setOpt(2)}}>Yesterday</Button>
                        </ButtonGroup>

                        { chartData ? (opt === 1 ||  opt === 2 || opt === 3 || opt === 4) ? <OpenChart data={chartData}/> : "" : "" }
                        { chartData ? (opt === 2 ||  opt === 3 || opt === 4) ? <OpenTable data={chartData} opt={opt} type='users'/> : "" : ""}
                    </div>
                </div>
            </div>
        </div>: ''
    );
}