import React, { useEffect, useState } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { CardData } from "../partials/CardData";
import { OpenChart } from "../partials/OpenChart";
import { OpenTable } from "../partials/OpenTable";
import axios from "axios";
import config from "../../lib/config";
import Grid from "@mui/material/Grid";

var backurl = config.baseurl;

export default function Transactions() {

    const [opt , setOpt] = useState(0);
    const [data, setData] = useState(false);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        axios
        .post(backurl + "/api/get-transactions-live")
        .then(res => {
            res.data ? setData(res.data) : setData(false);
        })
        .catch();
    }, []);

    useEffect(() => {
        if(opt != 0) {
            axios
            .post(backurl + "/api/get-transactions", { opt: opt })
            .then(res => {
                let dummyData = {
                    dates: [],
                    totalSwaps: [],
                    totalVolumes: [],
                    totalFees: [],
                    id: 2
                }

                res.data.answer.forEach(data => {
                    let dummySwapsCount = 0;
                    let dummyDate = new Date(data.createdAt).toUTCString().split(' ');
                    dummyData.dates.push(dummyDate[0] + " " + dummyDate[1] + " " + dummyDate[2]);
                    dummySwapsCount += (data.router.biswap + data.router.bitdrive + data.router.pancakeswap);

                    dummyData.totalSwaps.push(dummySwapsCount);
                    dummyData.totalVolumes.push(data.totalVolume);
                    dummyData.totalFees.push(data.totalFee);
                });
                setChartData(dummyData);
            })
            .catch();
        }
    }, [opt]);

    return (
        data ?
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <div className="container text-center">
                        <h2 className="text-primary mt-4 text-left">All Time</h2>
                        <hr/>
                        
                        <Grid className="pt-4" container spacing={6}>
                            <CardData type="single" labels={{label1: "Total Swaps",label2: ""}} values={{value1: data.totalVolume.router.biswap + data.totalVolume.router.bitdrive + data.totalVolume.router.pancakeswap,value2: ""}} />
                            <CardData type="single" labels={{label1: "Total Volume",label2: ""}} values={{value1: data.totalVolume.totalVolume.toFixed(2),value2: ""}} />
                            <CardData type="single" labels={{label1: "Total Fee",label2: ""}} values={{value1: data.totalVolume.totalFee.toFixed(2),value2: ""}} />
                        </Grid>

                        <h2 className="text-primary mt-4 text-left pt-4">Today</h2>
                        <hr/>

                        <Grid className="pt-4" container spacing={6}>
                            <CardData type="single" labels={{label1: "Total Swaps",label2: ""}} values={{value1: data.result.router.biswap + data.result.router.bitdrive + data.result.router.pancakeswap,value2: ""}} />
                            <CardData type="single" labels={{label1: "Total Volume",label2: ""}} values={{value1: data.result.totalVolume.toFixed(2),value2: ""}} />
                            <CardData type="single" labels={{label1: "Total Fee",label2: ""}} values={{value1: data.result.totalFee.toFixed(2),value2: ""}} />
                        </Grid>

                        <ButtonGroup className="pt-5 pb-4" aria-label="outlined primary button group">
                            <Button variant={opt === 1 ? "contained" : "outlined"} onClick={() => {setOpt(1)}}>All Time</Button>
                            <Button variant={opt === 4 ? "contained" : "outlined"} onClick={() => {setOpt(4)}}>Last 30 days</Button>
                            <Button variant={opt === 3 ? "contained" : "outlined"} onClick={() => {setOpt(3)}}>Last 7 days</Button>
                            <Button variant={opt === 2 ? "contained" : "outlined"} onClick={() => {setOpt(2)}}>Yesterday</Button>
                        </ButtonGroup>

                        { chartData ? (opt === 1 ||  opt === 2 || opt === 3 || opt === 4) ? <OpenChart data={chartData}/> : "" : "" }
                        { chartData ? (opt === 2 ||  opt === 3 || opt === 4) ? <OpenTable data={chartData} opt={opt} type='transactions'/> : "" : ""}
                        
                    </div>
                </div>
            </div>
        </div>: ''
    );
}