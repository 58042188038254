import axios from "axios";
import isEmpty from "is-empty";
import config from "../lib/config"
import {
    SETTINGS_UPDATE,
    GET_SETTINGS,
    GET_ERRORS
} from "./types";

var backurl = config.backurl;
var backurl = config.baseurl;


export const getSettings = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${backurl}/api/get-settings`
        });
        return {
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false
        }
    }
}

export const updateSettings = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${backurl}/api/update-settings`,
            'data': data,
        });
        return {
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            loading: false
        }
    }
}

