import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import classnames from "classnames";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSettings, getSettings } from "../../actions/settingsAction";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import config from "../../lib/config";
import { withRouter } from "react-router-dom";
const url = config.baseUrl;
const options = [{ 'value': "Enable", 'label': "Enable" }, { 'value': "Disable", 'label': "Disable" }];
class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: "",
            secretKey: "",
            adminaddress: "",
            errors: {}
        };
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {

    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };


    async getData() {
        var getdata = await getSettings();
        console.log(getdata, 'getdatagetdata')
        var secretKey = (getdata && getdata.result && getdata.result.secretKey) ? getdata.result.secretKey : "";
        var adminaddress = (getdata && getdata.result && getdata.result.adminaddress) ? getdata.result.adminaddress : "";
        this.setState({ secretKey: secretKey, adminaddress: adminaddress });
    }

    onSettingsUpdate = async e => {

        var data = {
            adminaddress: this.state.adminaddress,
            secretKey: this.state.secretKey,
        }

        await updateSettings(data);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">

                            <h3 className="mt-2 text-secondary">Settings</h3>
                            <form id="update-settings">
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="name">Private Key</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.secretKey}
                                            placeholder="Private Key"
                                            id="secretKey"
                                            type="password"
                                            error={errors.secretKey}
                                            className={classnames("form-control", {
                                                invalid: errors.secretKey
                                            })} />
                                        <span className="text-danger">{errors.secretKey}</span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="adminaddress">Admin address</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.adminaddress}
                                            placeholder="Admin address"
                                            id="adminaddress"
                                            type="text"
                                            error={errors.adminaddress}
                                            className={classnames("form-control", {
                                                invalid: errors.adminaddress
                                            })} />
                                        <span className="text-danger">{errors.adminaddress}</span>
                                    </div>
                                </div>

                            </form>
                            <br />
                            <button
                                form="update-settings"
                                type="button"
                                onClick={this.onSettingsUpdate}
                                className="btn btn-primary">
                                Update Settings
                                </button>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}


Settings.propTypes = {
    //updateSettings: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(Settings));

