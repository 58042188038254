var key;
if (process.env.NODE_ENV == "production") {
    key = {
        baseurl: "https://dexapi.bitdrive.com",
        factory: "0xEE08FEe63DDd34F610682D98C7F0187c793089B9",
        masterchef: "0x7b2421bEa68bb2CA89236eCD06Ea1C9069A5F42d",
    }
}
else {
    key = {
        baseurl: "http://localhost:2053",
        factory: "0xEE08FEe63DDd34F610682D98C7F0187c793089B9",
        masterchef: "0x7b2421bEa68bb2CA89236eCD06Ea1C9069A5F42d",
    }
}
export default key;
